<template>
  <div class="page">
    <div class="entry" @click="callWinShow('true')" v-if="entryShow === 'true'">
      <div>
        <van-image
          class="icon"
          :src="require('../../../assets/images/online.png')"
        ></van-image>
      </div>
      <div class="text">在线客服</div>
    </div>
    <div class="call">
      <van-popup
        :show="windowShow === 'true'"
        class="win"
        position="bottom"
        style="height: 80%"
      >
        <van-row class="title">
          <van-col span="12"
            ><van-icon name="notes-o" />
            <span v-if="active === 'online'">在线咨询</span>
            <span v-if="active === 'phone'">电话咨询</span>
            <span v-if="active === 'wechat'">微信咨询</span>
          </van-col>
          <van-col span="2" offset="10"
            ><van-icon
              name="cross"
              class="close"
              size="23"
              @click="callWinShow('false')"
          /></van-col>
        </van-row>
        <div class="content">
          <iframe
            class="online"
            v-if="active === 'online'"
            height="100%"
            width="98%"
            src="https://kefu.ywkefu.com/admin/api/chatGroup/h5/2104"
          ></iframe>
          <div v-if="active === 'phone'" class="phone">
            <div class="icon"><van-icon name="phone-circle-o" size="50" /></div>
            <div class="number">15029291243</div>
            <div class="call-btn">
              <van-button
                icon="phone-o"
                size="small"
                :color="COLOR_M"
                @click="callPhone('15029291243')"
                >拨打电话</van-button
              >
            </div>
          </div>
          <div v-if="active === 'wechat'" class="wechat">
            <van-image
              class="qrcode"
              :src="require('../../../assets/images/call/ewx.png')"
            ></van-image>
            <div class="text color-s1">请长按或扫描上方二维码添加微信咨询</div>
          </div>
        </div>

        <van-row class="btn">
          <van-col span="8"
            ><van-button
              icon="phone-o"
              size="small"
              :color="COLOR_M"
              @click="activeChange('online')"
              >在线咨询</van-button
            ></van-col
          >
          <van-col span="8"
            ><van-button
              icon="phone-o"
              size="small"
              :color="COLOR_S1"
              @click="activeChange('phone')"
              >电话咨询</van-button
            ></van-col
          >
          <van-col span="8"
            ><van-button
              icon="chat-o"
              size="small"
              :color="COLOR_S2"
              @click="activeChange('wechat')"
              >微信咨询</van-button
            ></van-col
          >
        </van-row>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { Popup, Image, Icon } from 'vant'
export default {
  components: {
    [Popup.name]: Popup,
    [Image.name]: Image,
    [Icon.name]: Icon
  },
  props: {
    winShow: { type: String, default: 'false' },
    entryShow: { type: String, default: 'false' },
    clueModel: { type: String, default: 'N' }
  },
  data () {
    return {
      windowShow: 'false',
      active: 'online'
    }
  },
  mounted () {
    this.windowShow = this.winShow
  },
  methods: {
    callWinShow (val) {
      this.windowShow = val
      if (this.clueModel !== 'N') {
        this.createClue()
      }
    },
    activeChange (val) {
      this.active = val
    },
    callPhone (number) {
      window.location.href = 'tel:' + number
    },
    async createClue () {
      var param = { url: window.location.href }
      var pd = {
        model: this.clueModel,
        extraCode: '0',
        param: JSON.stringify(param),
        content: '在线咨询'
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/clue/pool/createClue', this.$qs.stringify(pd))
      if (res.status !== '200') {
        console.log(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.entry {
  position: fixed;
  top: 400px;
  left: 10px;
  width: 60px;
  height: 60px;
  z-index: 99;

  .icon {
    margin-top: 3px;
    width: 50px;
    height: 50px;
  }

  .text {
    font-size: 10px;
    font-weight: 600;
    background-color: #fff;
  }
}

.call {
  .win {
    height: 100%;
    .title {
      font-size: 15px;
      font-weight: 600;
      text-align: left;
      padding-left: 10px;
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid #eee;
    }
    .close {
      margin-top: 5px;
    }
    .content {
      height: 80%;
      .online {
        border: medium none;
      }
      .phone {
        .icon {
          padding-top: 30px;
        }
        .number {
          font-size: 20px;
          font-weight: 600;
          margin: 50px 0px;
        }
      }
      .wechat {
        padding-top: 30px;
        .qrcode {
        }
        .text {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }

    .btn {
      border-top: 1px solid #eee;
      padding-top: 10px;
    }
  }
}
</style>
